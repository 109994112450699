import { useState, useEffect } from 'react';
import { isPro } from '../../api/users'; // Ensure correct import path
import { useAuth } from '../../contexts/AuthContext';

const pricingPlans = [
  {
    name: 'FREE',
    price: { monthly: 0, yearly: 0 },
    description: 'Perfect for those just getting started with interviews or exploring AI-assisted preparation.',
    features: [
      '3 x 15-min Interview Sessions for Mock Interviews or Real-Time Interviews',
      'Interview Feedback',
    ],
    buttonText: 'Try for free',
  },
  {
    name: 'PRO',
    price: { monthly: 29, yearly: 24 },
    description: 'Unlimited support to enhance your interview skills with advanced AI-driven tools.',
    features: [
      'Unlimited 60-min Interview Sessions for Mock Interviews or Real-Time Interviews',
      'Advanced Interview Feedback with detailed performance insights',
      'Full Access to Coding Copilot for technical interviews',
      'Personalized AI Assistant to tackle behavioral and experience-based interviews',
      'Priority Customer Support for faster assistance',
    ],
    buttonText: 'Get the plan',
  },
];

export function PricingPage() {
  const [isYearly, setIsYearly] = useState(true);
  const [isProUser, setIsProUser] = useState(false);
  const stripeCheckoutUrlYearly = 'https://buy.stripe.com/00g4ij8Tg5byaRieUU';
  const stripeCheckoutUrlMonthly = 'https://buy.stripe.com/cN2g117Pc6fC2kMeUX';
  const { session } = useAuth();

  useEffect(() => {
    const checkProStatus = async () => {
      const proStatus = await isPro(session?.user?.id);
      setIsProUser(proStatus);
    };
    checkProStatus();
  }, []);

  return (
    <section className="max-w-6xl mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold mb-4">Pricing Plans</h1>
        <p className="text-xl text-gray-600">
          Choose the plan that's right for you
        </p>
        <p className="text-lg text-gray-500">
          Flexible pricing options to suit your needs and budget
        </p>
      </div>
      
      <div className="flex justify-center items-center mb-8">
        <div className="relative flex items-center bg-white rounded-full p-0.5">
          <span 
            className={`absolute h-full top-0 transition-all duration-300 ease-in-out rounded-full bg-indigo-500 ${
              isYearly ? 'left-1/2 w-1/2' : 'left-0 w-1/2'
            }`}
          ></span>
          <button
            className={`relative z-20 px-4 py-2 text-sm transition-colors duration-300 ${
              !isYearly ? 'text-black' : 'text-gray-400'
            }`}
            onClick={() => setIsYearly(false)}
          >
            Monthly
          </button>
          <button
            className={`relative z-20 px-4 py-2 text-sm transition-colors duration-300 ${
              isYearly ? 'text-black' : 'text-gray-400'
            }`}
            onClick={() => setIsYearly(true)}
          >
            Yearly
          </button>
        </div>
        <div className="w-24 ml-4">
          <span 
            className={`px-3 py-1 text-xs font-bold text-white bg-purple-500 rounded-full transition-opacity duration-300 ${
              isYearly ? 'opacity-100' : 'opacity-0'
            }`}
          >
            SAVE 73%
          </span>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {pricingPlans.map((plan, index) => (
          <div key={index} className="border rounded-lg p-6 flex flex-col justify-between">
            <div>
              <h2 className="text-xl font-semibold text-indigo-500">{plan.name}</h2>
              <div className="text-3xl font-bold">
                ${isYearly ? plan.price.yearly : plan.price.monthly}
                {plan.price.monthly !== 0 && (
                  <span className="text-lg text-gray-500 ml-2">
                    /month
                    {isYearly && <span className="text-sm ml-1">billed annually</span>}
                  </span>
                )}
              </div>
            </div>
            <ul className="mb-6">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex} className="flex items-center mb-2">
                  <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            {plan.name === 'PRO' && isProUser ? (
              <button
                className="block w-full text-center bg-gray-300 text-gray-600 py-2 rounded cursor-not-allowed mt-auto"
                disabled
              >
                You are on this plan
              </button>
            ) : (
              plan.name !== 'FREE' && (
                <a
                  href={isYearly ? stripeCheckoutUrlYearly : stripeCheckoutUrlMonthly}
                  className="block w-full text-center bg-indigo-500 text-white py-2 rounded hover:bg-indigo-600 mt-auto"
                >
                  {plan.buttonText}
                </a>
              )
            )}
          </div>
        ))}
      </div>
    </section>
  );
}