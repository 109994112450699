/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Button, Card, Collapse, CollapseProps, Space, Select } from "antd";
import useScreenSharing from "../hooks/useScreenSharing";
import mixpanel from "../../../lib/mixpanel";
import { AudioOutlined } from "@ant-design/icons";
import { AudioStatusEnum } from "../types";


interface IProps {
  onNext: (e: boolean) => void;
  onPrevious?: () => void;
  onScreenShareParams: (params: any) => void;
  onLaunchInterview: () => void;
  launchInterviewLoading: boolean;
  onStopScreenSharing: () => void;
  isMockInterview: boolean;
  defaultShowVideo: boolean;
  setDefaultShowVideo: (e: boolean) => void;
}

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Try Yourself",
    children: (
      <p>
        The easiest way is to use a YouTube mock interview video. Open any mock
        interview video on the browser and share screen with the tab that the
        YouTube video is opened in. Here are some recommended YouTube videos to
        try our interview copilot on:
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=2zKsBfsrxrs&t=54s"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue" }}
            >
              Interview Simulation
            </a>
          </li>
        </ul>
        Feel free to use any industry specific mock interview videos you can
        find that fit your specific needs.
        <img
          src="/images/general-step.png"
          alt="General Step"
          className="mt-4 w-full max-w-md"
        />
      </p>
    ),
  },
  {
    key: "2",
    label: "Connect with Zoom",
    children: (
      <div>
        <p>
          To connect interview copilot with Zoom, you have to launch Zoom in the
          browser:
        </p>
        <ol>
          <li>Open the Zoom meeting link.</li>
          <li>When asked whether to open the Zoom app, select 'Cancel'.</li>
          <img
            src="/images/zoom-step-1.png"
            alt="Zoom Step 1"
            className="mt-4 w-full max-w-md"
          />
          <li>Select 'Join from Your Browser'.</li>
          <img
            src="/images/zoom-step-2.png"
            alt="Zoom Step 2"
            className="mt-4 w-full max-w-md"
          />
          <li>
            If you cannot see the 'Join from Your Browser' button, select
            'Launch Meeting', then select 'Cancel' when asked to open the Zoom
            app. You may have to repeat 'Launch Meeting' and 'Cancel' a few
            times before seeing the 'Join from Your Browser' button.
          </li>
          <li>
            Once the Zoom meeting is launched in the browser, navigate back to
            Nextoffer. Share screen with the tab that the Zoom meeting is
            opened in. Remember to also share the tab audio by toggling the
            switch.
          </li>
        </ol>
      </div>
    ),
  },
  {
    key: "3",
    label: "Connect with Microsoft Teams",
    children: (
      <div>
        <p>
          To connect interview copilot with Teams, you have to launch Teams in
          the browser:
        </p>
        <ol>
          <li>Open the Teams meeting link.</li>
          <li>Select 'Continue on this browser'.</li>
          <img
            src="/images/teams-step-1.png"
            alt="Teams Step 2"
            className="mt-4 w-full max-w-md"
          />
          <li>
            Once the Teams meeting is launched in the browser, navigate back to
            Nextoffer. Share screen with the tab that the Teams meeting is
            opened in. Remember to also share the audio by toggling the switch.
          </li>
        </ol>
      </div>
    ),
  },
  {
    key: "4",
    label: "Connect with Google Meet",
    children: (
      <p>
        Google Meet is by default launched in the browser. To connect interview
        copilot with Google Meet, simply share screen with the tab that the
        Google Meet meeting is opened in. Remember to also share the audio by
        toggling the switch.
      </p>
    ),
  },
  {
    key: "5",
    label: "Connect with phone interviews",
    children: (
      <div>
        <p>
          To connect your interview copilot with phone interviews, follow these
          steps using Google Voice:
        </p>
        <ol>
          <li>Open Google Voice in your browser.</li>
          <li>
            Test your microphone and speakers to ensure they are working
            correctly.
          </li>
          <li>
            To enable Nextoffer to hear the call, share your Google Voice
            screen with Nextoffer during the interview. This allows
            Nextoffer to capture audio and provide real-time support.
          </li>
        </ol>
        <p>
          By following these steps, you can seamlessly integrate Nextoffer
          into your phone interviews for optimal performance.
        </p>
      </div>
    ),
  },
  {
    key: "6",
    label: "Connect with Other Video Conferencing Tools",
    children: (
      <p>
        To connect interview copilot with any other video conferencing tool, you
        will need to launch the video conferencing tool in the browser. Then,
        navigate back to Nextoffer. Share screen with the tab that the video
        conferencing tool is opened in. Remember to also share the audio by
        toggling the switch.
      </p>
    ),
  },
];


const ConnectMeeting = ({
  onNext,
  onPrevious = () => { },
  onScreenShareParams,
  onLaunchInterview,
  launchInterviewLoading,
  onStopScreenSharing,
  isMockInterview,
  defaultShowVideo,
  setDefaultShowVideo
}: IProps) => {
  const {
    screenStream: videoStream,
    audioStream,
    combinedAudioStream,
    isSharing,
    handleStart,
    handleStop,
    videoRef,
    screenAudioStatus,
    microphoneStatus,
    handleStartMicrophoneOnly,
    audioDevices,
    selectedDeviceId,
    setSelectedDeviceId,
  } = useScreenSharing(onStopScreenSharing);

  const faqRef = useRef<HTMLDivElement>(null);

  const [volumeLevel, setVolumeLevel] = useState(0);

  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    } else {
      videoRef.current!.srcObject = null;
    }
  }, [videoStream]);

  useEffect(() => {
    onScreenShareParams({
      isSharing,
      audioStream,
      videoStream,
      combinedAudioStream,
      handleStop,
    });
  }, [isSharing, audioStream, videoStream, combinedAudioStream, handleStop]);

  useEffect(() => {
    if (!audioStream) return;

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(audioStream);
    source.connect(analyser);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const updateVolume = () => {
      analyser.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
      // Increase sensitivity by lowering the max value from 128 to 64
      setVolumeLevel(Math.min(average / 8, 1));
      requestAnimationFrame(updateVolume);
    };

    updateVolume();

    return () => {
      audioContext.close();
    };
  }, [audioStream]);

  const handleCollapseChange = (key: string | string[]) => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleStartMicrophoneOnly();
    console.log("selectedDeviceId", selectedDeviceId);
  }, []);

  // useEffect(() => {
  //   console.log("microphoneStatus", microphoneStatus);
  //   if (microphoneStatus === AudioStatusEnum.ERROR) {
  //     onPrevious();
  //   }
  // }, [microphoneStatus]);

  return (
    <>
      <Card title="Connect with Interview Copilot">
        <div className="flex flex-col items-center gap-4">
          <div className="w-full max-w-3xl">
            <video
              ref={videoRef}
              className="w-full bg-slate-200"
              style={{ aspectRatio: '16/9' }}
              autoPlay
              muted
              playsInline
            />
            <p className="text-gray-500 text-center">
              To enable real-time assistance during your interview, please share your interview <b>Chrome tab</b> with audio enabled. 
              Select "<b>Chrome Tab</b>" when sharing and ensure "<b>Also share tab audio</b>" is checked. Watch
              <a
                href="https://www.youtube.com/watch?v=xoQw3duNaN4"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline mx-1"
              >
                Interview Assistant Demo
              </a>
              for a detailed guide.
            </p>
            <p className="text-gray-500 text-center mt-2">
              For coding interviews, install our Coding Copilot extension:
              <a
                href="https://chromewebstore.google.com/detail/nextoffer/admjdhoojcmjmdjlkdojnpbbiajooajg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline ml-1"
              >
                Nextoffer Chrome Extension
              </a>
            </p>
            <p className="text-gray-500 text-center mt-2">
              Watch 
              <a
                href="https://www.youtube.com/watch?v=Vp2MRMhpWTs"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline ml-1"
              >
                Coding Copilot Demo
              </a>
            </p>
            <Space className="mt-4 w-full flex flex-col items-center justify-center">
              <div className="flex flex-col items-center w-[300px]">
                <div className="flex items-center w-full gap-2 mb-2">
                  <AudioOutlined />
                  <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-500 transition-all duration-100"
                      style={{ width: `${volumeLevel * 100}%` }}
                    />
                  </div>
                </div>
                <Select
                  value={selectedDeviceId}
                  onChange={setSelectedDeviceId}
                  className="w-full max-w-md mb-4"
                >
                  {audioDevices.map(device => (
                    <Select.Option key={device.deviceId} value={device.deviceId}>
                      {device.label || `Microphone ${device.deviceId}`}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              
              <div className="flex gap-4">
                {!isSharing ? (
                  <Button
                    onClick={() => {
                      handleStart();
                      onNext(true);
                      mixpanel.track("Connect Interview Screen");
                    }}
                  >
                    Connect interview screen 🖥️
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleStop();
                      onNext(false);
                      mixpanel.track("Stop Interview Screen Sharing");
                    }}
                  >
                    Stop Sharing
                  </Button>
                )}

                <Button
                  loading={launchInterviewLoading}
                  disabled={isMockInterview ? !selectedDeviceId : !isSharing}
                  onClick={() => {
                    if (isMockInterview) {
                      if (!isSharing) {
                        setDefaultShowVideo(false);
                        handleStart(isMockInterview);
                      } else {
                        setDefaultShowVideo(true);
                      }
                      setTimeout(() => {
                        onLaunchInterview();
                      }, 1000);
                    } else {
                      setDefaultShowVideo(true);
                      onLaunchInterview();
                    }
                    mixpanel.track("Launch Interview");
                  }}
                  type="primary"
                >
                  Launch Interview 🚀
                </Button>
              </div>
            </Space>

          </div>
        </div>
      </Card>
      <div ref={faqRef} className="mt-6 mb-4 text-lg font-bold">
        FAQ
      </div>
      <Collapse accordion items={items} onChange={handleCollapseChange} />
    </>
  );
};

export default ConnectMeeting;